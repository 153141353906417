import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Pricing Manager | Revenue Management Dpt– TUNISAIR January 2020 – Today',
        projectDesc: 'Project Management : evaluation of the existing, analysis of needs, participation in drafting the specifications and tendering procedure. Planning, time and risk management, human resources management, management of relations with partners, doing presentations and conference-calls Organisation : definition of the pricing strategy by market and allocation of human and technical resources, creating dashboards and monitoring of KPIs by market Coaching : support for junior pricing analysts, integration of new recruits and preparation of a training plan.',
        tags: ['Carried out Projects:',  'ATC Refund', 'Amadeus Dynamic Waiver', 'Amadeus Airline Fare Families','Fare’s database redesign','Acquisition of competitor airfare data monitoring' ],
        code: '',
        demo: '',
        image: one
    },
    {
        id: 2,
        projectName: 'Pricing Analyst |France & Europe Network –Revenue Management Dpt– TUNISAIR June 2015 – December 2019',
        projectDesc: 'Ensure price monitoring and competition analysis in order to maintain the agreed positioning within the Pricing / Revenue Management strategy assigned to the market Design and update of fare grids by route and set up forcast revenue scenarios Design of fare products adapted to each segment of passenger traffic, event and market specifications Manage a database to analyze passenger behavior according to several predefined parameters and update fare conditions. ',
        tags: ['Achieved projects :', 'Change of the baggage policy and migrate from Weight Concept to Piece Concept','Automatic pricing of excess and special baggage through SRs', 'Update and harmonise of the ticket change policy via CAT31 and ATC Reissue'],
        code: '',
        demo: '',
        image: two
    },
    {
        id: 3,
        projectName: 'Revenue Management Analyst |Southern Europe Network – Revenue Management Dpt – TUNISAIR January 2013 – May 2015',
        projectDesc: 'Analyzing demand using forecasting tools and adapt supply to forecast demand (scheduling of additional flights, change of aircraft type in order to act on capacity, etc.)',
        tags: ['Update the historical database and the reference curves', 'Check in the inventory the seat quota allocation scheme by booking class by flight/date and adjust constantly according to the evolution of booking and the recommendation of the RMS.', "Calculate and apply the overbooking rate for flights with high No-Show/cancellation potential","Coordinate with Pricing Analyst to update fares and apply specific restrictions per flight(s)/date(s)", "Make sure to find the optimal combination between revenue and load factor by flight/date"],
        code: '',
        demo: '',
        image: three
    },
    {
        id: 4,
        projectName: 'B2B Sales Analyst |France & Southern Europe Network – Sales Dpt – TUNISAIR – Tunisia October 2010 – January 2013',
        projectDesc: 'Validation of requests for quotations of charter operations and bloc-seat agreements and drafting of related contract.',
        tags: ['Design of seasonal fare grids for individual, group and allotments for each TO/partner', 'Analysis of sales by channel and by fare type and monitoring of the achievements of Tour Operators', 'Participation in the development of profit-sharing agreements'],
        code: '',
        demo: '',
        image: four
    },
    {
        id: 5,
        projectName: 'Junior Financial Analyst | Analysis and financial engineering Dpt – BNAC Broker July 2009 – September 2010',
        projectDesc: 'Determination and evaluation of sector activity indicators from the financial statements of listed companies on the stock exchange.',
        tags: ['Calculation of stock market indicators and measurement of securities performance', 'Collection of relevant informations on companies through the specialized press, the publications of the companies themselves and the report of EGMs, OGMs', 'Analysis of the various key performance and making recommendations regarding the purchase, sale or retention of securities.'],
        code: '',
        demo: '',
        image: five
    },
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/