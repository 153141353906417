import resume from '../assets/pdf/resume.pdf'
import marwen from '../assets/png/marwen.png'

export const headerData = {
    name: 'Marwen Mzoughi',
    title: "",
    desciption:"Revenue Management : 13 years experience in the air transport sector as an Analyst Pricing Manager in the national airline of tunisia <Tunisair>.",
    image: marwen,
    resumePdf: resume
}
