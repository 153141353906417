export const achievementData = {
    bio : "The illiterate of the 21st century will not be those who cannot read and write, but those who cannot learn, unlearn, and relearn. - Alvin Toffler",
    achievements : [
        {
            id : 1,
            title : 'ATPCO CAT31/33',
            details : 'Automation of voluntary changes and voluntary refunds',
            date : '',
            field : '',
            image : 'https://udemy-certificate.s3.amazonaws.com/image/UC-0cd075aa-f55b-4d9f-aec0-119020a4e792.jpg?v=1611846350000'
        },
        {
            id : 2,
            title : 'ATPCO OPTIONAL SERVICES & BRANDED FARES',
            details : 'Implementation of optional services and branded fare policies',
            date : '',
            field : '',
            image : 'https://udemy-certificate.s3.amazonaws.com/image/UC-91ae3ea2-39ee-4a57-bf23-d397aff0d9a7.jpg?v=1621951317000'
        },
        {
            id : 3,
            title : 'ATPCO FAREMANAGER',
            details : 'Creation and management of database of fares and conditions',
            date : '',
            field : '',
            image : 'https://udemy-certificate.s3.amazonaws.com/image/UC-0cd075aa-f55b-4d9f-aec0-119020a4e792.jpg?v=1611846350000'
        },
        {
            id : 4,
            title : 'AMADEUS ARD WEB',
            details : 'Advanced training on how to create a PNR, sell ancillary services, search for fares, issue and exchange documents…',
            date : '',
            field : '',
            image : 'https://udemy-certificate.s3.amazonaws.com/image/UC-91ae3ea2-39ee-4a57-bf23-d397aff0d9a7.jpg?v=1621951317000'
        },
        {
            id : 5,
            title : 'ATPCO Carrier-Imposed Fees',
            details : 'Creation and management of carrier-imposed fees and ticketing fees',
            date : '',
            field : '',
            image : 'https://udemy-certificate.s3.amazonaws.com/image/UC-0cd075aa-f55b-4d9f-aec0-119020a4e792.jpg?v=1611846350000'
        },
        {
            id : 6,
            title : 'Profitline/Yield',
            details : 'Initiation to the revenue management system and demand forecast',
            date : '',
            field : '',
            image : 'https://udemy-certificate.s3.amazonaws.com/image/UC-91ae3ea2-39ee-4a57-bf23-d397aff0d9a7.jpg?v=1621951317000'
        },
        {
            id : 7,
            title : 'IATA Airline Management',
            details : 'Dynamic pricing and revenue management (basic concepts) Route forecasting and development (analysis of new routes profitability)',
            date : '',
            field : '',
            image : 'https://udemy-certificate.s3.amazonaws.com/image/UC-0cd075aa-f55b-4d9f-aec0-119020a4e792.jpg?v=1611846350000'
        },

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/