export const educationData = [
    {
        id: 1,
        institution: 'School of Business in Tunis - ESCT',
        course: 'Master’s Degree in Financial Engineering',
        startYear: '2008',
        endYear: '2009'
    },
    {
        id: 2,
        institution: 'School of Business in Tunis - ESCT',
        course: 'Bachelor’s Degree in Management of Financial Institutions',
        startYear: '2004',
        endYear: '2008'
    },
    {
        id: 3,
        institution: 'Farabi High School',
        course: 'High School Diploma in Mathematics',
        startYear: '2003',
        endYear: '2004'
    },
]